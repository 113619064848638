/* @import url('https://fonts.googleapis.com/css2?family=Freeman&family=Noto+Serif+JP:wght@400;500&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Silkscreen:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

:root {

    --main-bg: #ffffff;
    --main-text: #7f7f7f;
    --emphasis-text: #000000;
    --border-color: #0f0f0f;

}

:root[theme="dark"] {

    --main-bg: #000000;
    --main-text: #7f7f7f;
    --emphasis-text: #ffffff;
    --border-color: #171717;

}

html {

    color: var(--main-text);
    background-color: var(--main-bg);

    margin: auto;

    font-family: "Roboto Condensed", serif;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;

}

body {

    width: 1280px;
    margin: auto;

}

a {

    color: var(--emphasis-text);
    /* text-decoration: none; */
    cursor: pointer;

}

b { color: var(--emphasis-text); }
img { /* filter: grayscale(100%); */ }
li { list-style: none; }
td { padding: 5px; }
h1, h2, h3, h4 { color: var(--emphasis-text); }

.header {

    width: 1280px;
    text-align: center;
    position: fixed;
    background-color: var(--main-bg);
}

.menu {

    display: inline-block;
    list-style: none;
    height: 20px;
    padding: 0px 70px 0px 70px;

}

.mode {

    vertical-align: top;
    margin: 5px;
    cursor: pointer;

}

.title {

    color: var(--emphasis-text);
    font-weight: 400;
    text-align: center;

}

.sub-title { margin-left: 100px; }
.emphasis { color: var(--emphasis-text); }
.left { float: left; }
.right { float: right; }

.main-title {

    height: 700px;
    width: 330px;
    /* border-right: solid 1px var(--border-color); */

}


.main-contents {

    width: 920px;
    padding-top: 80px;
    padding-bottom: 60px;

}

.main-logo {

    width: 150px;
    height: 150px;
    margin-top: 200px;
    margin-left: 120px;
    position: fixed;

}

.canvas {
    width: 920px;
}

.square-container {
    float: left;
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.square-container img {
    width: 99%;
    height: 99%;
}

.contents {
    /* width: 825px; */
    margin: 50px;
}

.key {
    width: 120px;
}

.footer {
    padding: 25px 0px 25px 0px;
    clear: both;
    border-top: solid 1px var(--border-color);
    vertical-align: middle;
}

.copyright { text-align: center; }

.sns { margin: 0px 15px 5px 0px; }

.code {
    background-color: #2f2f2f;
    padding: 10px;
    color: var(--main-text);
    border: solid 1px var(--border-color);
    border-radius: 15px;
}

.text {
    margin-bottom: 15px;
}

.message {
    margin: 20px 0px 20px 0px;
    clear: both;
}

.who-created-this {
    width: 200px;
}

.identify-colmun-left { width: 250px; }
.identify-colmun-right { width: 560px; }
.rounded { border-radius: 15px; }
p { cursor: pointer; }
p img { transition: all 0.3s; }

p:hover img {
  transform: scale(1.8);
  background-color: var(--main-bg);
}

.margin {
    margin-bottom: 500px;
}
